import { SIGN_IN_USER, SIGN_OUT_USER, SIGN_IN_TOKEN } from "./authConstants";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
    authenticated: false,
    currentUser: null,
    prevLocation: null,
    currentLocation: null,
};

export default function authReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SIGN_IN_USER:
            return {
                ...state,
                authenticated: true,
                currentUser: {
                    email: payload.email,
                    token: payload.token,
                    logged_in_at: payload.logged_in_at,
                    name: payload.name,
                    last_name: payload.last_name,
                    id: payload.id,
                    profile_image: payload.profile_image,
                    isAdmin: payload.isAdmin,
                    isSales: payload.isSales,
                    isLakato: payload.isLakato,
                    isCustomer: payload.isCustomer,
                    customer_code: payload.customer_code,
                    branch: payload.branch,
                    CustomerClass: payload.CustomerClass,
                    CustomerOnHold: payload.CustomerOnHold,
                    multi_account: payload.multi_account,
                    main_admin: payload.main_admin,
                    ShipToAddr1: payload.ShipToAddr1,
                    see_stock: payload.see_stock,
                    warehouse99: payload.warehouse99,
                    guest_login: payload.guest_login,
                    supplier_stock: payload.supplier_stock,
                    allowed_supplier: payload.allowed_supplier,
                    price_list: payload.price_list,
                    paym8_privacy: payload.paym8_privacy,
                },
            };
        case SIGN_IN_TOKEN:
            return {
                ...state,
                authenticated: true,
                currentUser: {
                    token: payload.token,
                },
            };
        case SIGN_OUT_USER:
            return {
                ...state,
                authenticated: false,
                currentUser: null,
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                prevLocation: state.currentLocation,
                currentLocation: payload.location,
            };
        default:
            return state;
    }
}
