import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
    faExclamationCircle,
    faHeart as solidHeart,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AdminNav from "../../../nav/AdminNav";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import CustomButton from "../../../../app/common/form/CustomButton";
import { useHistory } from "react-router";
import { openModal } from "../../../../app/common/modals/modalReducer";
import ReactTooltip from "react-tooltip";
import { toast, ToastContainer } from "react-toastify";
import { current } from "@reduxjs/toolkit";

export default function Cart(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const creditCardRef = useRef(null);
    const commentRef = useRef(null);

    const debug = 0;

    const { currentUser } = useSelector((state) => state.auth);

    const [products, setProducts] = useState([]);
    const [freeProducts, setFreeProducts] = useState([]);
    const [cartTotal, setCartTotal] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [pageNumber, setPageNumber] = useState("0");
    const [cartHasPromo, setCartHasPromo] = useState(0);
    const [contactUser, setContactUser] = useState(false);
    const [specialQualify, setSpecialQualify] = useState([]);
    const [specialProducts, setSpecialProducts] = useState([]);
    const [scriptUploaded, setScriptUploaded] = useState(false);
    const [hasScript, setHasScript] = useState(false);
    const [totalFree, setTotalFree] = useState(0);
    const [first, setFirst] = useState(true);
    const [totalPrice, setTotalPrice] = useState("0");
    const [cashPayment, setCashPayment] = useState(false);
    const [paymentType, setPaymentType] = useState(
        currentUser.isCustomer === 1 ? 1 : 2
    );

    const [orderPlaced, setOrderPlaced] = useState(false);

    const [toUpdate, setToUpdate] = useState(false);

    const [totalToAccept, setTotalToAccept] = useState(0);
    const [totalAccepted, setTotalAccepted] = useState(0);

    const [reRunOrder, setReRunOrder] = useState(false);
    const [backorderAsk, setBackorderAsk] = useState(false);
    const [qtyIssue, setQtyIssue] = useState(false);
    const [salespeople, setSalespeople] = useState([]);
    const [payM8Privacy, setPayM8Privacy] = useState(true);

    //Check if we have a token in the url, this means we have a successful payment
    const location = useLocation();

    useEffect(async () => {
        getProducts(0);
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );

        if (reRunOrder === true) {
            PlaceThisOrder(
                [products, freeProducts].flat(),
                window.sessionStorage.getItem("po_no"),
                totalPrice,
                window.sessionStorage.getItem("salesperson")
            );
            setReRunOrder(false);
        }

        console.log(currentUser);

        if (
            (currentUser.isLakato === 1 || currentUser.isAdmin === 1) &&
            salespeople.length === 0
        ) {
            let salesResp = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}get_salespeople`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
            });

            setSalespeople(salesResp.data);
        }
    }, [reRunOrder]);

    useEffect(async () => {
        //Promos
        //Get active promotions
        let promoResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}active-specials`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                customer_code:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
            },
        });
        let promoData = promoResults.data;

        let qualify = [];
        let qualifyId = [];

        let toAccept = 0;

        for (const key of Object.keys(promoData)) {
            const promo = promoData[key];
            const promoItems = promo.items;
            const allRules = promo.rules;

            // Check if any of the promo items are in the cart
            const isPromoItemInCart = Object.keys(promoItems).some((index) => {
                const promoItem = promoItems[index];
                return products.some(
                    (cartItem) => cartItem.col0 === promoItem.product_stock_code
                );
            });

            if (
                isPromoItemInCart &&
                promo.accept_terms === 1 &&
                freeProducts.length > 0
            ) {
                toAccept += 1; // Increment toAccept counter if a promo item is in the cart and accept_terms is 1
            }

            for (const no of Object.keys(allRules)) {
                for (const index of Object.keys(promoItems)) {
                    const currentPromoItem = promoItems[index];
                    if (
                        currentPromoItem.product_type === 1 &&
                        products.includes(currentPromoItem.product_stock_code)
                    ) {
                        qualifyId.push({
                            prodcustId: currentPromoItem.product_stock_code,
                            promoID: promo.id,
                            discount_type: promo.discount_type,
                            discount_percentage: promo.discount_percentage,
                            free_amount: promo.free_amount,
                            promotionType: allRules[no].rule_type,
                            rule_size: allRules[no].rule_size,
                            rule_qty: allRules[no].rule_qty,
                            accept_terms: promo.accept_terms,
                        });
                    }
                }
            }
        }

        setTotalToAccept(toAccept);
        setSpecialQualify(qualify);
    }, [products, freeProducts]);

    async function createTemplate(orderItems) {
        dispatch(
            openModal({
                modalType: "TemplateName",
                modalProps: { orderItems: orderItems },
            })
        );
    }

    async function getProducts(time) {
        debug && debug === 1 && console.log("getProducts");

        setHasScript(false);

        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");

        let cartItems = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-items-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                id: currentUser.id,
                priceCode:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                branch: currentUser.branch,
                customer_code: currentUser.customer_code,
            },
        });

        if (!token) {
            let termscode = cartItems.data[4].TermsCode
                ? parseInt(cartItems.data[4].TermsCode)
                : 1;
            if (termscode === 2 && currentUser.isCustomer === 1) {
                setCashPayment(true);
            }

            console.log(termscode);
        }

        let newPrevItemIds = [];

        await Object.keys(cartItems).forEach(function (key) {
            newPrevItemIds.push(cartItems[key].stock_code);
        });

        //Get items in basket
        let productResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-favourite-products`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                priceCode:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                category: "",
                favIds: newPrevItemIds,
            },
        });
        let prodData = productResults.data;

        let allproducts = [];
        let secondProds = [];
        // let alreadyQualify = [];

        let allcartItems = cartItems.data[0];
        let totalsCartItems = cartItems.data[1];
        let prodDataSpecial = cartItems.data[2];
        let prodDataSubCode = cartItems.data[3];

        let allCodes = [];

        prodDataSubCode.forEach(function (subCode) {
            allCodes.push(subCode.stock_code);
        });

        let totalPriceAccum = 0;

        let LineNumber = 1;
        await Object.keys(allcartItems).forEach(async function (key) {
            if (
                allcartItems[key].ProductGroup == "PG008" &&
                allcartItems[key].UserField3 == "6"
            ) {
                setHasScript(true);
            }

            let totals = 0;
            await Object.keys(totalsCartItems).forEach(async function (index) {
                if (
                    totalsCartItems[index].stock_code ===
                    allcartItems[key].StockCode
                ) {
                    totals = totalsCartItems[index].qty;
                }
            });

            let display = "";

            if (
                prodDataSubCode.length > 0 &&
                allCodes.includes(allcartItems[key].StockCode)
            ) {
                prodDataSubCode.forEach(function (subCode) {
                    if (subCode.stock_code == allcartItems[key].StockCode) {
                        display = subCode.custom_code;
                    }
                });
            }

            let promoId = [];
            let promoArr = [];

            let promoType = [];
            let promoOnType = [];
            let promoAmountType = [];
            let promoAmount = [];
            let promoDiscountAmount = [];
            let promoFreeAmount = [];
            let applyTerms = [];

            prodDataSpecial.forEach(function (promo) {
                let items = promo.items;
                let onPromo = [];

                let currentCartItemCode = allcartItems[key].StockCode;

                items.forEach(function (item) {
                    if (item.product_stock_code === currentCartItemCode) {
                        // If there's a match, proceed with further checks and potentially push to onPromo
                        if (item.product_type == "1") {
                            onPromo.push("yes");
                        } else if (item.product_type == "2") {
                            onPromo.push("free");
                        }
                    }
                });

                onPromo.forEach(async function (itemsPromo) {
                    if (itemsPromo !== undefined) {
                        promoArr.push(onPromo[0]);
                        if (onPromo[0] == "yes" || onPromo[0] == "free") {
                            promoId.push(promo.id);
                            promoType.push(promo.discount_type);
                            promoOnType.push(promo.rules[0].rule_type);
                            promoAmountType.push(promo.rules[0].rule_size);
                            promoAmount.push(promo.rules[0].rule_qty);
                            promoDiscountAmount.push(promo.discount_percentage);
                            promoFreeAmount.push(promo.free_amount);
                            applyTerms.push(promo.accept_terms);
                            setCartHasPromo(1);
                        }
                    }
                });
            });

            let acceptTnc = "no";
            let acceptTncArr = [];
            let acceptedPromotions = JSON.parse(
                window.sessionStorage.getItem("promotionAccepted")
            );

            if (acceptedPromotions !== null) {
                acceptedPromotions.forEach(function (accept) {
                    if (accept == allcartItems[key].StockCode) {
                        acceptTncArr.push("yes");
                    }
                });
            }

            if (acceptTncArr[0] === "yes") {
                acceptTnc = "yes";
            }

            let cartData = {
                col0: allcartItems[key].StockCode,
                col1: allcartItems[key].col1,
                col2: allcartItems[key].StockCode,
                col3: allcartItems[key].Description,
                col4: allcartItems[key].InvWarehouse,
                col5: allcartItems[key].SellingPrice
                    ? "R " +
                      parseFloat(allcartItems[key].SellingPrice).toFixed(2)
                    : "Free",
                col6: totals,
                col8: allcartItems[key].LongDesc,
                col10: allcartItems[key].ProductGroup,
                col11: allcartItems[key].AlternateUom,
                col12: promoArr[0],
                col13: promoId[0],
                col14: promoType[0],
                col15: promoOnType[0],
                col16: promoAmountType[0],
                col17: promoAmount[0],
                col18: promoDiscountAmount[0],
                col19: promoFreeAmount[0],
                col20: acceptTnc,
                col21: allcartItems[key].UserField3,
                col22: applyTerms[0],
                col23:
                    allcartItems[key].special_prices !== "" &&
                    allcartItems[key].special_prices !== null
                        ? "R " +
                          parseFloat(allcartItems[key].special_prices).toFixed(
                              2
                          )
                        : "",
                col24: display,
                col25: allcartItems[key].backorder,
                col26: LineNumber,
            };

            let price = parseFloat(allcartItems[key].SellingPrice).toFixed(2);
            if (
                allcartItems[key].special_prices !== "" &&
                allcartItems[key].special_prices !== null
            ) {
                price = parseFloat(allcartItems[key].special_prices).toFixed(2);
            }

            totalPriceAccum = totalPriceAccum + price * totals;

            allproducts.push(cartData);
            LineNumber = LineNumber + 1;
        });

        setTotalPrice(parseFloat(totalPriceAccum).toFixed(2));

        let allNormalProds = [];
        let allFreeProds = [];
        let new_allprods = allproducts;

        await Object.keys(allproducts).forEach(async function (key) {
            let reqBuyTotal = "";

            if (allproducts[key].col12 == "free") {
                let itemsIncartForPromo = [];
                await Object.keys(prodDataSpecial).forEach(async function (
                    promo
                ) {
                    let specialId = allproducts[key].col13;
                    let items = prodDataSpecial[promo].items;

                    await Object.keys(items).forEach(async function (index) {
                        if (
                            items[index].product_type == "1" &&
                            prodDataSpecial[promo].id == specialId
                        ) {
                            itemsIncartForPromo.push(
                                items[index].product_stock_code
                            );
                            reqBuyTotal =
                                prodDataSpecial[promo].rules[0].rule_qty;
                        }
                    });
                });

                let result = allproducts.map(({ col2 }) => col2);
                let found = result.some((r) => itemsIncartForPromo.includes(r));

                let totalInCart = 0;

                new_allprods.forEach(function (prod) {
                    if (itemsIncartForPromo.includes(prod.col2)) {
                        totalInCart += prod.col6;
                    }
                });

                if (found == true && totalInCart >= reqBuyTotal) {
                    allFreeProds.push(allproducts[key]);
                } else {
                    allNormalProds.push(allproducts[key]);
                }
            } else {
                allNormalProds.push(allproducts[key]);

                if (time === 0) {
                    updateFreeInfo(
                        allproducts[key].col6,
                        allproducts[key].col0,
                        allproducts[key]
                    );
                }
            }
        });

        setProducts(allNormalProds);
        setFreeProducts(allFreeProds);
        setSpecialProducts(secondProds);

        debug && debug === 1 && console.log(allproducts);
        debug && debug === 1 && console.log(allNormalProds);
        debug && debug === 1 && console.log(allFreeProds);
        debug && debug === 1 && console.log(secondProds);

        //Check if we have any products with a 0 qty

        let qtyOK = true;
        Object.keys(allNormalProds).forEach(function (key) {
            if (allNormalProds[key].col6 <= 0) {
                qtyOK = false;
            }
        });

        if (qtyOK === false) {
            setQtyIssue(true);
        } else {
            setQtyIssue(false);
        }

        if (first === true) {
            setFirst(false);
        }

        //Now check if we need to redo the order after cash payment

        if (token) {
            if (token === "error" || token === "nopayment") {
                alert(
                    "We have received your order but something went wrong with the payment. Contact Lakato to arrange payment."
                );
            } else {
                setCashPayment(false);
            }
        }
    }

    async function RemovePromoItemFromCart(itemId, promoId) {
        let prevPromoItems = JSON.parse(
            window.sessionStorage.getItem("promotionItemsInCart")
        );

        let newPromo = [];
        if (prevPromoItems && prevPromoItems.length > 0) {
            await Object.keys(prevPromoItems).forEach(function (key) {
                if (prevPromoItems[key][2] !== promoId) {
                    newPromo.push(prevPromoItems[key]);
                }
            });
        }

        window.sessionStorage.setItem(
            "promotionItemsInCart",
            JSON.stringify(newPromo)
        );

        //Get the product that we are removing, we need to also remove the linked promo item
        if (cartHasPromo == 1) {
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}remove-items-from-cart`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    StockCode: itemId,
                    id: currentUser.id,
                    customer_code: currentUser.customer_code,
                    remove_promo_item: 1,
                },
            });
        }

        RemoveItemFromCart(itemId);
    }

    async function RemoveItemFromCart(itemId) {
        itemId = itemId.trim();
        setOrderPlaced(true);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove-items-from-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                customer_code: currentUser.customer_code,
            },
        });

        setOrderPlaced(false);

        let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket");
        let NewAmount = parseFloat(CurrentCartAmount) - 1;
        window.sessionStorage.setItem("itemsInBasket", NewAmount);

        await getProducts(1);
    }

    async function setTotals(totalAmount, itemId, rowInfo) {
        debug &&
            debug === 1 &&
            console.log("setTotals", totalAmount, itemId, rowInfo);

        if (rowInfo.col12 == "yes" && rowInfo.col14 == "2") {
            updateFreeInfo(totalAmount, itemId, rowInfo);
        }

        itemId = itemId.trim();

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: totalAmount,
                customer_code: currentUser.customer_code,
            },
        });

        await getProducts(1);
    }

    async function updateFreeInfo(totalAmount, itemId, rowInfo) {
        // setToUpdate(true)

        if (toUpdate === true) {
            setToUpdate(false);
        } else {
            setToUpdate(true);
        }

        if (freeProducts.length > 0) {
            await Object.keys(freeProducts).forEach(async function (key) {
                if (freeProducts[key].col13 === rowInfo.col13) {
                    let itemIdFree = freeProducts[key].col2.trim();

                    await axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
                        headers: {
                            Authorization: `Bearer ${currentUser.token}`,
                        },
                        data: {
                            StockCode: itemIdFree,
                            id: currentUser.id,
                            qty: Math.floor(
                                totalAmount / parseFloat(rowInfo.col17)
                            ),
                            customer_code: currentUser.customer_code,
                        },
                    });

                    await getProducts(1);
                }
            });
        } else {
            if (totalAmount >= rowInfo.col17) {
                let activeSpecial = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}special-details`,
                    headers: {
                        Authorization: `Bearer ${currentUser.token}`,
                    },
                    data: { promotionID: rowInfo.col13 },
                });

                let specialItems = activeSpecial.data.items;

                await Object.keys(specialItems).forEach(async function (key) {
                    if (specialItems[key].product_type == "2") {
                        //ADD FREE ITEM TO CART
                        itemId = itemId.trim();

                        let cartItem = await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
                            headers: {
                                Authorization: `Bearer ${currentUser.token}`,
                            },
                            data: {
                                StockCode: specialItems[key].product_stock_code,
                                id: currentUser.id,
                                qty: 1,
                                auto: 1,
                                customer_code: currentUser.customer_code,
                            },
                        });

                        // toast.success("Free Item Added to Cart", {position: "bottom-right", autoClose: 5000, closeOnClick: true,});

                        await getProducts(1);
                    }
                });

                // getProducts();
            }
        }
    }

    async function openPromo(value) {
        let activeSpecial = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}special-details`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { promotionID: value },
        });

        let specialData = [activeSpecial.data];
        let specialInfo = [];

        await Object.keys(specialData).forEach(async function (key) {
            let loopItems = specialData[key].items;
            let products = [];
            let free = [];

            await Object.keys(loopItems).forEach(async function (index) {
                if (loopItems[index].product_type == "1") {
                    products.push(loopItems[index].product_stock_code);
                }
                if (loopItems[index].product_type == "2") {
                    free.push(loopItems[index].product_stock_code);
                }
            });

            specialInfo.push({
                col0: specialData[key].id,
                col1: specialData[key].promotion_name,
                col2: specialData[key].from_date,
                col3: specialData[key].to_date,
                col4: specialData[key].discount_type,
                col5: specialData[key].free_amount,
                col6: specialData[key].discount_percentage,
                col7: specialData[key].special_type,
                col8: specialData[key].req_buy_amount,
                col9: specialData[key].free_product_type,
                col9: specialData[key].limit_on_order,
                col10: specialData[key].limit_per_day,
                col11: products,
                col12: free,
                col13: specialData[key].special_on,
                col14: specialData[key].ProductsGroup,
                col15: specialData[key].rules,
                col16: specialData[key].file_url,
                col17: specialData[key].promo_desc,
            });
        });

        dispatch(
            openModal({
                modalType:
                    activeSpecial.data.discount_type == "1"
                        ? "PromotionDiscount"
                        : "PromotionFree",
                modalProps: { Promotion: specialInfo[0] },
            })
        );
    }

    const data = React.useMemo(() => products, [products]);

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        #
                    </div>
                ),
                accessor: "col26",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Code
                    </div>
                ),
                accessor: "col2",
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col24 !== ""
                                ? rowInfo.row.original.col24
                                : rowInfo.row.original.col2}
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Description
                    </div>
                ),
                accessor: "col3",
                Cell: (rowInfo) => {
                    return (
                        <>
                            <span>{rowInfo.row.original.col3}</span>
                            <br />
                            <span>{rowInfo.row.original.col8}</span>{" "}
                            &nbsp;&nbsp;
                            <span className="text-red-500">
                                {rowInfo.row.original.col10 == "PG008" ||
                                rowInfo.row.original.col10 == "PG003" ||
                                rowInfo.row.original.col10 == "PG007" ||
                                rowInfo.row.original.col10 == "PG001" ? (
                                    <>
                                        {/* <br/>  */}
                                        {rowInfo.row.original.col10 ==
                                        "PG008" ? (
                                            <>
                                                <span data-tip="NON-RETURNABLE. This is a product registered under the Medicines and Related Substance Act 101 of 1965 as Schedule 6 and requires a prescription to be submitted to Lakato. This product is non-returnable">
                                                    <FontAwesomeIcon
                                                        icon={faQuestionCircle}
                                                    />
                                                </span>{" "}
                                                <ReactTooltip />
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {rowInfo.row.original.col10 ==
                                            "PG003" ||
                                        rowInfo.row.original.col10 ==
                                            "PG007" ? (
                                            <>
                                                <span data-tip="NON-RETURNABLE. This is a cold-chain product and is non-returnable">
                                                    <FontAwesomeIcon
                                                        icon={faQuestionCircle}
                                                    />
                                                </span>{" "}
                                                <ReactTooltip />
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {rowInfo.row.original.col10 ==
                                        "PG001" ? (
                                            <>
                                                <span data-tip="NON-RETURNABLE. This product registered under the Medicines and Related Substance Act 101 of 1965 and is non-returnable">
                                                    <FontAwesomeIcon
                                                        icon={faQuestionCircle}
                                                    />
                                                </span>{" "}
                                                <ReactTooltip />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </span>
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Stock Status
                    </div>
                ),
                accessor: "col4",
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col4 === "Out of Stock" ? (
                                <span className="text-red-500">
                                    {rowInfo.row.original.col4}
                                </span>
                            ) : (
                                <span>{rowInfo.row.original.col4}</span>
                            )}
                            <br />
                            {rowInfo.row.original.col12 === "yes" &&
                            rowInfo.row.original.col4 != "Out of Stock" ? (
                                <>
                                    <br />
                                    <span
                                        className="bg-red-600 px-2 py-2 text-white text-center cursor-pointer"
                                        onClick={() =>
                                            openPromo(
                                                rowInfo.row.original.col13
                                            )
                                        }
                                    >
                                        On Promotion !
                                    </span>
                                </>
                            ) : (
                                ""
                            )}

                            {rowInfo.row.original.col23 !== "" &&
                            rowInfo.row.original.col12 !== "yes" &&
                            rowInfo.row.original.col4 !== "Out of Stock" &&
                            parseFloat(
                                rowInfo.row.original.col23.replace("R ", "")
                            ) <
                                parseFloat(
                                    rowInfo.row.original.col5.replace("R ", "")
                                ) ? (
                                <>
                                    <br />
                                    <span className="bg-red-600 px-2 py-2 text-white text-center cursor-pointer">
                                        On Promotion !
                                    </span>
                                </>
                            ) : (
                                ""
                            )}
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Price Excl. VAT
                    </div>
                ),
                accessor: "col5",
                Cell: (rowInfo) => {
                    return (
                        <>
                            {rowInfo.row.original.col14 == "1" &&
                            rowInfo.row.original.col12 === "yes" &&
                            rowInfo.row.original.col6 >=
                                rowInfo.row.original.col17 ? (
                                <>
                                    <strike className="text-red-400">
                                        R{" "}
                                        {parseFloat(
                                            rowInfo.row.original.col5.replace(
                                                "R ",
                                                ""
                                            )
                                        ).toFixed(2)}
                                    </strike>
                                    <br />R{" "}
                                    {(
                                        parseFloat(
                                            rowInfo.row.original.col5.replace(
                                                "R ",
                                                ""
                                            )
                                        ) -
                                        parseFloat(
                                            rowInfo.row.original.col5.replace(
                                                "R ",
                                                ""
                                            )
                                        ) *
                                            parseFloat(
                                                "0." +
                                                    (parseFloat(
                                                        rowInfo.row.original
                                                            .col18
                                                    ) > 9
                                                        ? rowInfo.row.original
                                                              .col18
                                                        : "0" +
                                                          rowInfo.row.original
                                                              .col18)
                                            )
                                    ).toFixed(2)}
                                </>
                            ) : (
                                <span>
                                    {rowInfo.row.original.col23 !== "" &&
                                    rowInfo.row.original.col23 !== null &&
                                    parseFloat(
                                        rowInfo.row.original.col23.replace(
                                            "R ",
                                            ""
                                        )
                                    ) <
                                        parseFloat(
                                            rowInfo.row.original.col5.replace(
                                                "R ",
                                                ""
                                            )
                                        ) ? (
                                        <>
                                            <del>
                                                {rowInfo.row.original.col5}
                                            </del>
                                            <br />
                                            {rowInfo.row.original.col23}
                                        </>
                                    ) : (
                                        <>{rowInfo.row.original.col5}</>
                                    )}

                                    {rowInfo.row.original.col11 == "SD" ? (
                                        <>
                                            <span
                                                className="text-green-500 ml-2"
                                                data-tip="Settlement Discount if Applicable"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faExclamationCircle}
                                                />
                                            </span>{" "}
                                            <ReactTooltip />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </span>
                            )}
                        </>
                    );
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        QTY
                    </div>
                ),
                accessor: "col6",
                Cell: (rowInfo) => {
                    return (
                        <>
                            <input
                                type="number"
                                min="0"
                                value={rowInfo.row.original.col6}
                                className="shadow-sm text-sm border border_radius_5 w-20"
                                onChange={(values) => {
                                    parseFloat(values.target.value) < 0
                                        ? setTotals(
                                              0,
                                              rowInfo.row.original.col2,
                                              rowInfo.row.original
                                          )
                                        : setTotals(
                                              values.target.value,
                                              rowInfo.row.original.col2,
                                              rowInfo.row.original
                                          );
                                    values.target.value = Math.abs(
                                        values.target.value
                                    );
                                }}
                            />
                        </>
                    );
                },
            },
            {
                Header: "",
                accessor: "col7",
                Cell: (rowInfo) => {
                    return (
                        <div>
                            {rowInfo.row.original.col12 == "yes" ? (
                                <>
                                    <span
                                        className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto cursor-pointer"
                                        disabled={orderPlaced}
                                        onClick={() =>
                                            RemovePromoItemFromCart(
                                                rowInfo.row.original.col2,
                                                rowInfo.row.original.col15
                                            )
                                        }
                                    >
                                        {orderPlaced === true
                                            ? "Removing..."
                                            : "Remove"}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span
                                        className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto cursor-pointer"
                                        disabled={orderPlaced}
                                        onClick={() =>
                                            RemoveItemFromCart(
                                                rowInfo.row.original.col2
                                            )
                                        }
                                    >
                                        {orderPlaced === true
                                            ? "Removing..."
                                            : "Remove"}
                                    </span>
                                </>
                            )}
                        </div>
                    );
                },
            },
        ],
        [toUpdate]
    );

    const tableInstance = useTable(
        { columns, data, manualPagination: true },
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
    } = tableInstance;

    async function acceptTnC(itemId, reqAmount, accepted, value) {
        // await setTotals(reqAmount, itemId)

        let acceptedPromotions = JSON.parse(
            window.sessionStorage.getItem("promotionAccepted")
        );

        if (acceptedPromotions == null) {
            window.sessionStorage.setItem(
                "promotionAccepted",
                JSON.stringify([itemId])
            );
        } else {
            window.sessionStorage.setItem(
                "promotionAccepted",
                JSON.stringify([...acceptedPromotions, itemId])
            );
        }

        //overHere

        if (value.target.checked === true) {
            let newAccepted = accepted + 1;
            setTotalAccepted(newAccepted);
        } else {
            let newAccepted = accepted - 1;
            setTotalAccepted(newAccepted);
        }

        await getProducts(1);
    }

    async function PlaceThisOrder(products, orderNo, fullPrice, salesperson) {
        //First check if we have any items with no Qty
        let qtyOK = true;
        Object.keys(products).forEach(function (key) {
            if (products[key].col6 <= 0) {
                qtyOK = false;
            }
        });

        if (qtyOK === false) {
            setQtyIssue(true);
            alert("Please make sure that all items have a Quantity above 0");
        } else {
            if (currentUser.CustomerOnHold === "Y") {
                dispatch(
                    openModal({
                        modalType: "OnHoldModal",
                        modalProps: { type: "cart" },
                    })
                );
            } else {
                if (totalToAccept === totalAccepted) {
                    if (scriptUploaded === false && hasScript === true) {
                        // setScriptUploaded(true);
                        dispatch(
                            openModal({
                                modalType: "ScriptHelp",
                                modalProps: {
                                    products: products,
                                    setScriptUploaded: setScriptUploaded,
                                },
                            })
                        );
                    } else {
                        setOrderPlaced(true);

                        //check if there are items on backorder

                        let backorderCheck = await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}check-backorder`,
                            headers: {
                                Authorization: `Bearer ${currentUser.token}`,
                            },
                            data: {
                                branch: currentUser.branch,
                                products: products,
                                customer_code: currentUser.customer_code
                                    ? currentUser.customer_code
                                    : "",
                            },
                        });

                        if (
                            backorderCheck.data.length > 0 &&
                            backorderAsk === false
                        ) {
                            setOrderPlaced(false);

                            dispatch(
                                openModal({
                                    modalType: "OrderBackorder",
                                    modalProps: {
                                        backorders: backorderCheck.data,
                                        setBackorderAsk: setBackorderAsk,
                                        setReRunOrder: setReRunOrder,
                                        currentUser: currentUser,
                                    },
                                })
                            );
                        } else {
                            let cashOk = true;

                            //If this is a Cash customer first handle payment
                            if (cashOk === true) {
                                setIsSubmitting(true);

                                let guid = await axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}place-order-session`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        userId: currentUser.id,
                                    },
                                });

                                if (guid.data === "offline-system") {
                                    alert(
                                        "Currently the system is under going maintenance and is not available for placing of orders"
                                    );

                                    setIsSubmitting(false);
                                    setOrderPlaced(false);
                                } else {
                                    // let firstPart = order_session.data.split("<LogonResult>");
                                    // let guid = firstPart[1].split("</LogonResult>");

                                    let order = await axios({
                                        method: "post",
                                        url: `${process.env.REACT_APP_API_URL}place-order-data`,
                                        headers: {
                                            Authorization: `Bearer ${currentUser.token}`,
                                            "Access-Control-Allow-Origin": "*",
                                        },
                                        data: {
                                            userId: currentUser.id,
                                            userEmail: currentUser.email,
                                            products: products,
                                            guid: guid.data,
                                            customer_code:
                                                currentUser.isCustomer === 1 &&
                                                currentUser.customer_code !==
                                                    null
                                                    ? currentUser.customer_code
                                                    : currentUser.customer_code ===
                                                          null ||
                                                      currentUser.customer_code.split(
                                                          ","
                                                      ).length > 1
                                                    ? "None"
                                                    : currentUser.customer_code,
                                            branch: currentUser.branch,
                                            orderNo: orderNo,
                                            totalPrice: fullPrice,
                                            payment_id:
                                                window.sessionStorage.getItem(
                                                    "paymentId"
                                                ),
                                            salesperson: salesperson,
                                            comment: commentRef.current.value,
                                        },
                                    });

                                    setIsSubmitting(false);

                                    if (order.data[0] == "Order Failed") {
                                        dispatch(
                                            openModal({
                                                modalType: "OrderFailed",
                                                modalProps: {
                                                    errors: order.data[1],
                                                },
                                            })
                                        );

                                        setIsSubmitting(false);
                                        setOrderPlaced(false);
                                    } else if (order.data[0] == "Stock Issue") {
                                        dispatch(
                                            openModal({
                                                modalType: "StockIssue",
                                                modalProps: {
                                                    errors: order.data[1],
                                                },
                                            })
                                        );
                                        window.sessionStorage.setItem(
                                            "itemsInBasket",
                                            0
                                        );
                                        window.sessionStorage.setItem(
                                            "itemsIdsInBasket",
                                            JSON.stringify([])
                                        );
                                        window.sessionStorage.setItem(
                                            "totalItemsInBasket",
                                            JSON.stringify([])
                                        );
                                        window.sessionStorage.setItem(
                                            "itemsClicked",
                                            JSON.stringify([])
                                        );
                                        window.sessionStorage.setItem(
                                            "promotionAccepted",
                                            JSON.stringify([])
                                        );

                                        setIsSubmitting(false);
                                    } else {
                                        await axios({
                                            method: "post",
                                            url: `${process.env.REACT_APP_API_URL}send-mail-order`,
                                            headers: {
                                                Authorization: `Bearer ${currentUser.token}`,
                                            },
                                            data: {
                                                userId: currentUser.id,
                                                name: currentUser.ship_addr_1,
                                                // name: currentUser.name + " " + currentUser.last_name,
                                                customer_code:
                                                    currentUser.isCustomer ===
                                                        1 &&
                                                    currentUser.customer_code !==
                                                        null
                                                        ? currentUser.customer_code
                                                        : currentUser.customer_code ===
                                                              null ||
                                                          currentUser.customer_code.split(
                                                              ","
                                                          ).length > 1
                                                        ? "None"
                                                        : currentUser.customer_code,
                                                orderItems: products,
                                                totalPrice: fullPrice,
                                                scriptUploaded: scriptUploaded,
                                                order_no: order.data[1],
                                                po_no: orderNo,
                                            },
                                        });

                                        window.sessionStorage.setItem(
                                            "itemsInBasket",
                                            0
                                        );
                                        window.sessionStorage.setItem(
                                            "itemsIdsInBasket",
                                            JSON.stringify([])
                                        );
                                        window.sessionStorage.setItem(
                                            "totalItemsInBasket",
                                            JSON.stringify([])
                                        );
                                        window.sessionStorage.setItem(
                                            "itemsClicked",
                                            JSON.stringify([])
                                        );
                                        window.sessionStorage.setItem(
                                            "promotionAccepted",
                                            JSON.stringify([])
                                        );

                                        //If this is a cash client check if it is a cli
                                        if (cashPayment === true) {
                                            //Check if this person is paying by Credit Card otherwise process as EFT or Account
                                            if (paymentType === 1) {
                                                cashOk = false;

                                                //Forward to lakato server instead of getting token first
                                                let this_pay_value =
                                                    parseFloat(fullPrice) *
                                                    1.15;
                                                window.location.href = `${process.env.REACT_APP_API_URL}paym8-create-payment/${this_pay_value}/${currentUser.customer_code}/${order.data[1]}/${currentUser.id}/${payM8Privacy}`;
                                                return;

                                                // let payment = await axios({
                                                //     method: "post",
                                                //     url: `${process.env.REACT_APP_API_URL}paym8-create-payment`,
                                                //     headers: {
                                                //         Authorization: `Bearer ${currentUser.token}`,
                                                //     },
                                                //     data: {
                                                //         value:
                                                //             parseFloat(
                                                //                 fullPrice
                                                //             ) * 1.15, //Add VAT for cash payments
                                                //         customer_code:
                                                //             currentUser.customer_code,
                                                //         order_id: order.data[1],
                                                //     },
                                                // });

                                                // if (
                                                //     payment.data[0] &&
                                                //     payment.data[1] &&
                                                //     payment.data[2] &&
                                                //     payment.data[0] === "ok" &&
                                                //     payment.data[2] > 0
                                                // ) {
                                                //     window.sessionStorage.setItem(
                                                //         "paymentId",
                                                //         payment.data[2]
                                                //     );
                                                //     alert(payment.data[1]);
                                                //     window.location.href =
                                                //         payment.data[1];
                                                //     return;
                                                // } else {
                                                //     alert(
                                                //         "error " +
                                                //             payment.data[1]
                                                //     );
                                                // }
                                            } else {
                                                history.push(
                                                    "/order/" + order.data[1]
                                                );
                                            }
                                        } else {
                                            history.push(
                                                "/order/" + order.data[1]
                                            );
                                        }
                                    }

                                    setIsSubmitting(false);
                                }
                            }
                        }
                    }
                } else {
                    dispatch(
                        openModal({
                            modalType: "OrderFailed",
                            modalProps: {
                                errors: [
                                    "please accept all the promotion terms and confitions",
                                ],
                            },
                        })
                    );
                }
            }
        }
    }

    let textGray =
        "text-gray-400 mx-auto text-center relative px-2 py-2 text-sm";

    async function createScriptTemplate(orderItems, freeProducts) {
        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}create-script-template`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                name: currentUser.ship_addr_1,
                // name: currentUser.name + " " + currentUser.last_name,
                customer_code:
                    currentUser.isCustomer === 1 &&
                    currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null ||
                          currentUser.customer_code.split(",").length > 1
                        ? "None"
                        : currentUser.customer_code,
                orderItems: [orderItems, freeProducts].flat(),
            },
        });
        window
            .open(
                process.env.REACT_APP_URL + "scripts/" + script.data,
                "_blank"
            )
            .focus();
    }

    async function createQuote(orderItems, freeProducts, fullPrice, type) {
        if (type === "pdf") {
            let script = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}create-quote-pdf`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    userId: currentUser.id,
                    name: currentUser.ship_addr_1,
                    // name: currentUser.name + " " + currentUser.last_name,
                    customer_code:
                        currentUser.isCustomer === 1 &&
                        currentUser.customer_code !== null
                            ? currentUser.customer_code
                            : currentUser.customer_code === null ||
                              currentUser.customer_code.split(",").length > 1
                            ? "None"
                            : currentUser.customer_code,
                    orderItems: [orderItems, freeProducts].flat(),
                    totalPrice: fullPrice,
                },
            });
            window
                .open(
                    process.env.REACT_APP_URL + "quotes/" + script.data,
                    "_blank"
                )
                .focus();
        } else {
            let script = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}create-quote-excel`,
                headers: { Authorization: `Bearer ${currentUser.token}` },
                data: {
                    userId: currentUser.id,
                    name: currentUser.ship_addr_1,
                    customer_code:
                        currentUser.isCustomer === 1 &&
                        currentUser.customer_code !== null
                            ? currentUser.customer_code
                            : currentUser.customer_code === null ||
                              currentUser.customer_code.split(",").length > 1
                            ? "None"
                            : currentUser.customer_code,
                    orderItems:
                        freeProducts.length > 0
                            ? [orderItems, freeProducts].flat()
                            : orderItems,
                    totalPrice: fullPrice,
                },
            });

            window
                .open(process.env.REACT_APP_URL + script.data, "_blank")
                .focus();
        }
    }

    async function addNewItems(file) {
        var formData = new FormData();
        var imagefile = document.querySelector("#importCart");
        formData.append("file", imagefile.files[0]);
        formData.append("userId", currentUser.id);
        formData.append("customer_code", currentUser.customer_code);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}import-to-cart`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });

        getProducts(0);
    }

    async function uploadScript(file) {
        var formData = new FormData();
        var imagefile = document.querySelector("#uploadFile");
        formData.append("file", imagefile.files[0]);
        formData.append("userId", currentUser.id);
        formData.append("customer_code", currentUser.customer_code);

        let script = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}upload-script`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });

        setScriptUploaded(script.data);
    }

    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Cart"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Shop", href: "/shop", count: 2 },
                    { name: "My Cart", href: "/view-cart", count: 3 },
                ]}
            />

            <br />
            <br />

            <div className="container mx-auto">
                <button
                    className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover uppercase"
                    onClick={() => createTemplate(products)}
                >
                    Make template
                </button>
                <button
                    className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover uppercase"
                    onClick={() =>
                        createQuote(products, freeProducts, totalPrice, "pdf")
                    }
                >
                    Print Quote PDF
                </button>
                <button
                    className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover uppercase"
                    onClick={() =>
                        createQuote(products, freeProducts, totalPrice, "excel")
                    }
                >
                    Print Quote Excel
                </button>

                {hasScript === true && (
                    <button
                        className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_red hover:bg-lk_red_hover uppercase"
                        onClick={() =>
                            createScriptTemplate(products, freeProducts)
                        }
                    >
                        Print Script Template
                    </button>
                )}

                <CustomButton
                    disabled={orderPlaced}
                    submitBool={orderPlaced}
                    type="submit"
                    text="Import Cart"
                    className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-blue-500 hover:bg-blue-700 uppercase"
                    // onClick={() => PlaceThisOrder(products)}
                    onClick={() =>
                        document.getElementById("importCart").click()
                    }
                    data-tip="Stock Code in column A, Quantity in column B"
                />
                <input
                    id="importCart"
                    name="importCart"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => {
                        addNewItems(event.target.files[0]);
                    }}
                    className="form-control"
                />
                <ReactTooltip />

                <table
                    {...getTableProps()}
                    className="mb-10 w-full"
                    style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                    }}
                >
                    <thead className="sticky top-32 lg:top-40 md:top-44 sm:top-48 z-10">
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="bg-gray-100 flex flex-col flex-no wrap sm:table-row w-full"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        className="px-2 py-2 font-light"
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <>
                                    <tr
                                        {...row.getRowProps()}
                                        className="bg-white rounded mt-5 flex flex-col flex-no wrap sm:table-row"
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <>
                                                    <td
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            // borderRight:
                                                            // "1px solid #EFEFEF",
                                                            textAlign: "left",
                                                        }}
                                                        className="mx-auto text-center relative px-2 py-2 text-sm lg:border-r md:border-r border-slate-300"
                                                    >
                                                        {cell.render("Cell")
                                                            .props.cell.column
                                                            .id === "col2" ||
                                                        cell.render("Cell")
                                                            .props.cell.column
                                                            .id === "col4" ? (
                                                            <p
                                                                className="text-gray-500 text-2xl lg:text-sm md:text-sm"
                                                                style={{
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <>
                                                                {cell.render(
                                                                    "Cell"
                                                                ).props.cell
                                                                    .column
                                                                    .id ===
                                                                "col3" ? (
                                                                    <p className="text-center lg:text-left md:text-left">
                                                                        {cell.render(
                                                                            "Cell"
                                                                        )}
                                                                    </p>
                                                                ) : (
                                                                    cell.render(
                                                                        "Cell"
                                                                    )
                                                                )}
                                                            </>
                                                            // cell.render("Cell")
                                                        )}
                                                    </td>
                                                </>
                                            );
                                        })}
                                    </tr>

                                    {/* {row.original.col12 == "yes" && (
                              <tr role="row" className="mt-0">
                                <td colSpan="7">
                                  <p className="ml-5">A salesperson will contact you to apply this promotion</p>
                                </td>
                              </tr>
                            )} */}
                                </>
                            );
                        })}
                    </tbody>
                </table>

                <ToastContainer />

                {freeProducts.length > 0 && (
                    <>
                        <CustomButton
                            disabled={true}
                            type="button"
                            text="Specials"
                            className="bg-gray-300 w-full border_radius_10 mb-5 px-4 py-3 uppercase"
                        />

                        <table
                            className="mb-10 w-full"
                            style={{
                                borderCollapse: "separate",
                                borderSpacing: "0 1em",
                            }}
                        >
                            <thead>
                                {headerGroups.map((headerGroup) => (
                                    <tr
                                        {...headerGroup.getHeaderGroupProps()}
                                        className="bg-gray-100"
                                    >
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps()
                                                )}
                                                className="px-2 py-5 font-light"
                                            >
                                                {column.render("Header")}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {freeProducts.map((free) => (
                                    <>
                                        {/* {free.col20 == 'yes' ? } */}
                                        <tr
                                            role="row"
                                            className="bg-white rounded mt-5"
                                            key={new Date()}
                                        >
                                            {/* <td
                                                role="cell"
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className={
                                                    free.col20 == "yes"
                                                        ? textGray
                                                        : "mx-auto text-center relative px-2 py-2 text-sm"
                                                }
                                            ></td> */}
                                            <td></td>
                                            <td
                                                role="cell"
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className={
                                                    free.col20 == "yes"
                                                        ? textGray
                                                        : "mx-auto text-center relative px-2 py-2 text-sm"
                                                }
                                            >
                                                {free.col0}
                                            </td>
                                            <td
                                                role="cell"
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className={
                                                    free.col20 == "yes"
                                                        ? textGray
                                                        : "mx-auto text-center relative px-2 py-2 text-sm"
                                                }
                                            >
                                                {free.col3}
                                                <br />
                                                {free.col8}
                                            </td>
                                            <td
                                                role="cell"
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className={
                                                    free.col20 == "yes"
                                                        ? textGray
                                                        : "mx-auto text-center relative px-2 py-2 text-sm"
                                                }
                                            >
                                                {free.col4}
                                            </td>
                                            <td
                                                role="cell"
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className={
                                                    free.col20 == "yes"
                                                        ? textGray
                                                        : "mx-auto text-center relative px-2 py-2 text-sm"
                                                }
                                            >
                                                {console.log(free)}
                                                {free.col20 === "no" ||
                                                free.col20 === '"no"' ? (
                                                    <>{free.col5}</>
                                                ) : (
                                                    <>
                                                        <strike className="text-red-400">
                                                            {free.col5}
                                                        </strike>
                                                        <br />R 0
                                                    </>
                                                )}
                                            </td>
                                            <td
                                                role="cell"
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className={
                                                    free.col20 == "yes"
                                                        ? textGray
                                                        : "mx-auto text-center relative px-2 py-2 text-sm"
                                                }
                                            >
                                                {/* <input type="number" min='0' disabled={true} defaultValue = {free.col6} className="shadow-sm text-sm border border_radius_5 w-20" onChange={(values) => {parseFloat(values.target.value) < 0 ? setTotals(0, free.col0) : setTotals(values.target.value, free.col0); getProducts(); values.target.value = Math.abs(values.target.value);}}/>  */}
                                                {free.col6}
                                            </td>
                                            <td
                                                role="cell"
                                                style={{
                                                    borderRight:
                                                        "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className={
                                                    free.col20 == "yes"
                                                        ? textGray
                                                        : "mx-auto text-center relative px-2 py-2 text-sm"
                                                }
                                            >
                                                <span>
                                                    {" "}
                                                    {free.col17} + {free.col19}{" "}
                                                    deal.
                                                </span>
                                            </td>
                                        </tr>
                                        {free.col20 === "no" && (
                                            <tr>
                                                <td colSpan="5">
                                                    <span>
                                                        Order {free.col17}x of
                                                        the following{" "}
                                                        <span
                                                            className="cursor-pointer text-blue-600"
                                                            onClick={() =>
                                                                openPromo(
                                                                    free.col13
                                                                )
                                                            }
                                                        >
                                                            qualifying products
                                                        </span>{" "}
                                                        you will receive{" "}
                                                        {free.col19}, to be
                                                        given away FREE with the
                                                        sale of the{" "}
                                                        <span
                                                            className="cursor-pointer text-blue-600"
                                                            onClick={() =>
                                                                openPromo(
                                                                    free.col13
                                                                )
                                                            }
                                                        >
                                                            qualifying product
                                                        </span>
                                                        . You may <br />
                                                        order more than one deal
                                                        but you will need to buy
                                                        in multiples of{" "}
                                                        {free.col17} units
                                                    </span>
                                                </td>
                                                <td colSpan="2">
                                                    {free.col22 === 1 && (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    acceptTnC(
                                                                        free.col2,
                                                                        free.col14,
                                                                        totalAccepted,
                                                                        value
                                                                    )
                                                                }
                                                            />{" "}
                                                            I accept the terms
                                                            and conditions
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                ))}
                            </tbody>
                            {/* freeProducts */}
                        </table>
                    </>
                )}
            </div>
            {/* {cartHasPromo == 1 && (
               <div className="container mx-auto flex flex-row-reverse mb-10">
                  <label>
                    <input type="checkbox" className="mr-2" onClick={(value) => {
                      setContactUser(value.target.checked)
                      }}/>
                    Would you like to have a salesperson contact you to ensure your order is correct?
                  </label>
                </div>
            )} */}

            <div className="container mx-auto grid grid-cols-2">
                <div>
                    <label className="block" htmlFor="comment">
                        Comment (Optional)
                    </label>
                    <textarea
                        name="comment"
                        rows="5"
                        className="w-full"
                        ref={commentRef}
                        maxLength="100"
                    ></textarea>
                </div>

                <div>
                    {products.length > 0 && (
                        <div className="flex flex-row-reverse mb-2">
                            <div>
                                <div className="grid grid-cols-2 gap-4">
                                    <div className="col-span-2">
                                        <div className="flex justify-between">
                                            <h2 className="text-lg font-bold mr-5">
                                                SUB TOTAL:
                                            </h2>
                                            <span className="text-lg font-bold">
                                                R{" "}
                                                {parseFloat(totalPrice).toFixed(
                                                    2
                                                )}
                                            </span>
                                        </div>
                                        <div className="flex justify-between">
                                            <h2 className="text-lg font-bold mr-5">
                                                VAT:
                                            </h2>
                                            <span className="text-lg font-bold">
                                                R{" "}
                                                {(
                                                    parseFloat(totalPrice) *
                                                    0.15
                                                ).toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="flex justify-between">
                                            <h2 className="text-lg font-bold mr-5">
                                                TOTAL (ZAR):
                                            </h2>
                                            <span className="text-lg font-bold">
                                                R{" "}
                                                {(
                                                    parseFloat(totalPrice) *
                                                    1.15
                                                ).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {products.length > 0 && (
                        <div className="flex flex-row-reverse mb-2">
                            <input
                                type="text"
                                onChange={(v) =>
                                    window.sessionStorage.setItem(
                                        "po_no",
                                        v.target.value
                                    )
                                }
                                maxlength="30"
                            />
                            <label
                                style={{ fontSize: "14px", lineHeight: "16px" }}
                                className="mt-2 mr-2"
                            >
                                Purchase order number:
                                <br />
                                <span
                                    style={{
                                        fontSize: "10px",
                                        lineHeight: "12px",
                                    }}
                                >
                                    30 characters max
                                </span>
                            </label>
                        </div>
                    )}

                    {(currentUser.isLakato === 1 ||
                        currentUser.isAdmin === 1) &&
                        salespeople.length > 0 && (
                            <div className="flex flex-row-reverse items-center mb-2">
                                <select
                                    name="salesperson"
                                    onChange={(v) =>
                                        window.sessionStorage.setItem(
                                            "salesperson",
                                            v.target.value
                                        )
                                    }
                                >
                                    <option value="">Don't Override</option>

                                    {salespeople?.map((sp) => (
                                        <option value={sp.Salesperson}>
                                            {sp.Name}
                                        </option>
                                    ))}
                                </select>
                                <label
                                    style={{
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                    }}
                                    className="mt-2 mr-2"
                                >
                                    Override Salesperson:
                                </label>
                            </div>
                        )}

                    <div className="text-right">
                        {scriptUploaded !== false && (
                            <p>Script Uploaded: {scriptUploaded}</p>
                        )}
                    </div>

                    <div className="flex flex-row-reverse">
                        {qtyIssue === true ? (
                            <p
                                style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    marginBottom: "20px",
                                }}
                            >
                                Error, you have items with a 0 QTY
                            </p>
                        ) : (
                            <>
                                {products.length > 0 && (
                                    <div>
                                        {cashPayment === true && (
                                            <div className="flex items-center justify-end mb-3 space-x-2">
                                                <input
                                                    name="paymentType"
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                        paymentType === 1
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() =>
                                                        setPaymentType(1)
                                                    }
                                                />
                                                <label className="pr-6">
                                                    Credit Card
                                                </label>

                                                <input
                                                    name="paymentType"
                                                    type="radio"
                                                    value="2"
                                                    checked={
                                                        paymentType === 2
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() =>
                                                        setPaymentType(2)
                                                    }
                                                />
                                                <label>EFT</label>
                                            </div>
                                        )}

                                        {cashPayment === true &&
                                            paymentType === 2 && (
                                                <div className="mb-3 max-w-sm">
                                                    <strong>
                                                        Lakato (Pty) Ltd
                                                        <br />
                                                        Standard Bank
                                                    </strong>
                                                    <br />
                                                    <strong>
                                                        EFT Branch Code:
                                                    </strong>{" "}
                                                    051 001
                                                    <br />
                                                    <strong>
                                                        Account No:
                                                    </strong>{" "}
                                                    000 633 860
                                                    <br />
                                                    <strong>
                                                        Reference:
                                                    </strong>{" "}
                                                    Your account number
                                                    <br />
                                                    <strong>
                                                        Kindly note:
                                                    </strong>{" "}
                                                    Transfers from a Standard
                                                    bank account will reflect on
                                                    the same day. All other
                                                    banks could take up to two
                                                    days to reflect.
                                                    <br />
                                                    <strong>
                                                        Please email proof of
                                                        payment to:
                                                    </strong>{" "}
                                                    debtors@lakato.co.za
                                                </div>
                                            )}

                                        {cashPayment === true &&
                                            paymentType === 1 && (
                                                <>
                                                    <div className="flex items-center justify-end mb-3 space-x-2">
                                                        <input
                                                            name="payM8Privacy"
                                                            type="checkbox"
                                                            value="1"
                                                            checked={
                                                                payM8Privacy
                                                            }
                                                            onChange={() =>
                                                                setPayM8Privacy(
                                                                    payM8Privacy ==
                                                                        true
                                                                        ? false
                                                                        : true
                                                                )
                                                            }
                                                        />
                                                        <label className="mr-3">
                                                            Store Credit Card
                                                            details
                                                        </label>
                                                    </div>

                                                    <div className="mb-3 max-w-sm">
                                                        *For Credit Card
                                                        Payments you will be
                                                        redirected to a secure
                                                        site that will process
                                                        your transaction.
                                                        <br />
                                                        *Diners and Amex cards
                                                        not accepted.
                                                    </div>
                                                </>
                                            )}

                                        <div className="flex items-center justify-end mb-3 max-w-lg">
                                            <CustomButton
                                                disabled={orderPlaced}
                                                submitBool={orderPlaced}
                                                type="submit"
                                                text="Place Order"
                                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-52 border_radius_10 mb-5"
                                                onClick={() =>
                                                    PlaceThisOrder(
                                                        [
                                                            products,
                                                            freeProducts,
                                                        ].flat(),
                                                        window.sessionStorage.getItem(
                                                            "po_no"
                                                        ),
                                                        totalPrice,
                                                        window.sessionStorage.getItem(
                                                            "salesperson"
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {hasScript === true && scriptUploaded === false && (
                            <>
                                <CustomButton
                                    disabled={orderPlaced}
                                    submitBool={orderPlaced}
                                    type="submit"
                                    text="Upload Script"
                                    className="lkbutton bg-blue-500 hover:bg-blue-700 w-52 border_radius_10 mb-5 mr-10"
                                    // onClick={() => PlaceThisOrder(products)}
                                    onClick={() =>
                                        document
                                            .getElementById("uploadFile")
                                            .click()
                                    }
                                />
                                <input
                                    id="uploadFile"
                                    name="uploadFile"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                        uploadScript(event.target.files[0]);
                                    }}
                                    className="form-control"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
