import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import axios from "axios";
import { useDispatch } from "react-redux";
import { signInUser, signInToken } from "../auth/authActions";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../app/common/form/CustomButton";
import CustomText from "../../app/common/form/CustomText";
import { closeModal } from "../../app/common/modals/modalReducer";
import * as Yup from "yup";

export function LoginForm() {
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    const [forgot, setForgot] = useState(false);
    const [resetSent, setResetSent] = useState(false);
    const [serverVersion, setServerVersion] = useState("");

    useEffect(() => {
        if (location.search) {
            let token = location.search.replace("?token=", "");

            dispatch(signInToken({ token: token }));

            // history.push("/dashboard/landing");
        }

        if (serverVersion === "") {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}version`,
            }).then((res) => {
                setServerVersion(res.data);
            });
        }
    });

    const initialValues = {
        email: "",
        password: "",
    };

    const forgetPassword = (e) => {
        e.preventDefault();
        setForgot(forgot === true ? false : true);
    };

    return (
        <div className="bg-white">
            {forgot === false ? (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize="true"
                    validationSchema={Yup.object({
                        email: Yup.string().required().email(),
                        password: Yup.string().required(),
                    })}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            let res = await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}login`,
                                data: {
                                    email: values.email,
                                    password: values.password,
                                },
                            });

                            // console.log(res.data.accounts[0])
                            let acc = res.data.accounts[0];
                            let accHold = "N";

                            if (acc !== undefined) {
                                accHold = res.data.accounts[0].CustomerOnHold;
                            }

                            let payload = {
                                email: res.data.user.email,
                                token: res.data.jwt,
                                logged_in_at: res.data.logged_in_at,
                                name: res.data.user.first_name,
                                last_name: res.data.user.last_name,
                                id: res.data.user.id,
                                profile_image: res.data.user.profile_image,
                                isAdmin: res.data.user.is_admin,
                                isSales: res.data.user.is_sales,
                                isLakato: res.data.user.is_lakato,
                                isCustomer: res.data.user.is_customer,
                                customer_code: res.data.user.customer_code,
                                branch: res.data.user.branch,
                                CustomerClass: res.data.user.CustomerClass,
                                customerOnHold: accHold,
                                multi_account: res.data.user.multi_account,
                                main_admin: res.data.user.main_admin,
                                ShipToAddr1: res.data.user.ShipToAddr1,
                                see_stock: res.data.user.see_stock,
                                warehouse99: res.data.user.warehouse99,
                                guest_login: res.data.user.guest_login,
                                supplier_stock: res.data.user.supplier_stock,
                                allowed_supplier:
                                    res.data.user.allowed_supplier,
                                price_list: res.data.user.price_list,
                                paym8_privacy: res.data.user.paym8_privacy,
                            };

                            // setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")))
                            let amount = await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}total-items-in-cart`,
                                headers: {
                                    Authorization: `Bearer ${res.data.jwt}`,
                                },
                                data: {
                                    id: res.data.user.id,
                                    customer_code: res.data.user.customer_code,
                                },
                            });

                            window.sessionStorage.setItem(
                                "itemsInBasket",
                                amount.data
                            );
                            window.sessionStorage.setItem(
                                "itemsIdsInBasket",
                                JSON.stringify([])
                            );
                            window.sessionStorage.setItem(
                                "totalItemsInBasket",
                                JSON.stringify([])
                            );
                            window.sessionStorage.setItem(
                                "itemsClicked",
                                JSON.stringify([])
                            );
                            window.sessionStorage.setItem(
                                "promotionItems",
                                JSON.stringify([])
                            );
                            window.sessionStorage.setItem(
                                "promotionItemsInCart",
                                JSON.stringify([])
                            );
                            window.sessionStorage.setItem(
                                "promotionAccepted",
                                JSON.stringify([])
                            );

                            window.sessionStorage.setItem(
                                "categoryForSearch",
                                JSON.stringify([])
                            );
                            window.sessionStorage.setItem(
                                "displayUpdated",
                                new Date()
                            );
                            window.sessionStorage.setItem("loggedIn", true);

                            dispatch(signInUser(payload));
                            dispatch(closeModal());

                            if (res.data.accounts.length > 1) {
                                history.push("/select-profile");
                            } else {
                                history.push("/shop");
                            }
                        } catch (error) {
                            setErrors({ message: error.response.data.error });
                        }
                    }}
                >
                    {({ isSubmitting, errors }) => (
                        <Form
                            className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto"
                            action="#"
                            method="POST"
                        >
                            <CustomText
                                label="EMAIL"
                                type="text"
                                name="email"
                            />
                            <CustomText
                                label="PASSWORD"
                                type="password"
                                name="password"
                            />
                            {errors.message && (
                                <p className="text-lk_red text-lg">
                                    {errors.message}
                                </p>
                            )}
                            <CustomButton
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="submit"
                                text="Login"
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-32 sm:w-96 border_radius_10"
                            />

                            <p className="uppercase text-sm">
                                FORGOTTEN PASSWORD?{" "}
                                <a
                                    href="#"
                                    onClick={forgetPassword}
                                    className="underline"
                                >
                                    CLICK HERE
                                </a>
                            </p>
                            <small>V 1.5.8</small>
                            {serverVersion !== "V 1.5.8" && (
                                <p className="text-lk_red">
                                    You are currently on an older version,
                                    please refresh your page (F5) or contact
                                    Lakato support.
                                    <br />
                                    Latest Version = {serverVersion}
                                </p>
                            )}
                        </Form>
                    )}
                </Formik>
            ) : (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize="true"
                    validationSchema={Yup.object({
                        email: Yup.string().required().email(),
                    })}
                    onSubmit={async (values, { setErrors }) => {
                        try {
                            let res = await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}forget-password`,
                                data: {
                                    email: values.email,
                                },
                            });

                            setResetSent(true);
                        } catch (error) {
                            setErrors({ message: error.response.data.error });
                        }
                    }}
                >
                    {({ isSubmitting, errors }) => (
                        <Form
                            className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto"
                            action="#"
                            method="POST"
                        >
                            <p>
                                Fill in your email address, we will send an
                                email to reset your password.
                            </p>

                            <CustomText
                                label="EMAIL"
                                type="text"
                                name="email"
                            />
                            {errors.message && (
                                <p className="text-lk_red text-lg">
                                    {errors.message}
                                </p>
                            )}
                            <CustomButton
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="submit"
                                text="Submit"
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-32 sm:w-96 border_radius_10"
                            />

                            {resetSent === true && (
                                <p>
                                    A reset link has been sent to your email
                                    with further instructions.
                                </p>
                            )}

                            <p className="uppercase text-sm">
                                <a
                                    href="#"
                                    onClick={forgetPassword}
                                    className="underline"
                                >
                                    CANCEL
                                </a>
                            </p>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
}
