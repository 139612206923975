import { faHeart, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import { useTable, usePagination, useSortBy } from "react-table";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import {
    faExclamationCircle,
    faHeart as solidHeart,
    faQuestionCircle,
    faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import { openModal } from "../../../../app/common/modals/modalReducer";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import CustomButton from "../../../../app/common/form/CustomButton";

export default function Favourites(props) {
    const dispatch = useDispatch();
    let history = useHistory();

    const { currentUser } = useSelector((state) => state.auth);

    const [favourites, setFavourites] = useState([]);
    const [products, setProducts] = useState([]);
    const [cartTotal, setCartTotal] = useState([]);
    const [pageNumber, setPageNumber] = useState("0");
    const [imgActive, setImgActive] = useState();
    const [stockActive, setStockActive] = useState();
    const [descActive, setDescActive] = useState();
    const [barActive, setBarActive] = useState();
    const [petBrandsActive, setPetBrandsActive] = useState();
    const [displaySettings, setDisplaySettings] = useState(false);
    const [codeLoading, setCodeLoading] = useState(false)

    if (currentUser.multi_account === true && (currentUser.customer_code == null || currentUser.customer_code == "None" || currentUser.customer_code.split(",").length > 1)) {
        window.location.replace('/select-profile')
    }

    const allowedSups = currentUser.supplier_stock != null ? currentUser.supplier_stock.split(",").map(function (item) { return item.trim(); }) : currentUser.supplier_stock;

    useEffect(() => {
        window.addEventListener("storage", (e) => {
            if (e.key == "displayUpdatedDate") {
                console.log('getFavProducts 7');
                getProducts();
            }
        });

        console.log('getFavProducts 1');
        getProducts();
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );
    }, []);

    async function getProducts() {
        let dispalyView = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-view-display`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { userId: currentUser.id },
        });

        console.log(dispalyView.data);

        if (dispalyView.data.display_img == 0) {
            setImgActive(false);
        } else {
            setImgActive(true);
        }

        if (dispalyView.data.display_code == 0) {
            setStockActive(false);
        } else {
            setStockActive(true);
        }

        if (dispalyView.data.display_desc == 0) {
            setDescActive(false);
        } else {
            setDescActive(true);
        }

        if (dispalyView.data.display_barCode == 0) {
            setBarActive(false);
        } else {
            setBarActive(true);
        }

        if (dispalyView.data.display_petBrands == 0) {
            setPetBrandsActive(false);
        } else {
            setPetBrandsActive(true);
        }

        let favouritesResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}favourites`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
            },
        });

        // console.log(currentUser)

        let favData = favouritesResults.data[0];
        let prodDataSubCode = favouritesResults.data[1];
        let favIds = [];

        await Object.keys(favData).forEach(function (key) {
            favIds.push(favData[key].favourite_product_id);
        });

        setFavourites(favIds);

        let allCodes = [];

        prodDataSubCode.forEach(function (subCode) {
            allCodes.push(subCode.stock_code);
        });

        // let prodData = [
        //   {col0: '1', col1: '/images/product.png', col2: '48618', col3: 'Pegasus VMIT 100', col4: '< 100', col5: 'R93.08'},
        //   {col0: '2', col1: '/images/product.png', col2: '48619', col3: 'Pegasus VMIT 200', col4: '< 100', col5: 'R103.08'},
        //   {col0: '3', col1: '/images/product.png', col2: '48620', col3: 'Pegasus VMIT 300', col4: '< 100', col5: 'R113.08'}
        // ]

        let totalItems = JSON.parse(
            window.sessionStorage.getItem("totalItemsInBasket")
        );

        // console.log(currentUser.id, currentUser.isCustomer === 1 && currentUser.customer_code !== null ? currentUser.customer_code : "None", '', favIds)

        let productResults = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-favourite-products`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                userId: currentUser.id,
                priceCode:
                    currentUser.isCustomer === 1 &&
                        currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
                category: "",
                favIds: favIds,
                branch: currentUser.branch,
            },
        });
        let prodData = productResults.data;
        let allproducts = [];

        let cartItems = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-items-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                id: currentUser.id,
                priceCode: currentUser.customer_code,
                branch: currentUser.branch,
            },
        });

        let cartItemData = cartItems.data[1];

        let specialList = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}active-specials`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: {
                customer_code:
                    currentUser.isCustomer === 1 &&
                        currentUser.customer_code !== null
                        ? currentUser.customer_code
                        : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1 ? "None" : currentUser.customer_code,
            },
        });

        let allSpecials = specialList.data;

        await Object.keys(prodData).forEach(async function (key) {
            let total = "0";
            // if(totalItems !== null){

            //   Object.keys(totalItems).forEach(function (index) {
            //       if(totalItems[index][1].trim() == prodData[key].StockCode.trim()){
            //           total = totalItems[index][0];
            //       }
            //   });
            // }

            let display = "";

            if (
                prodDataSubCode.length > 0 &&
                allCodes.includes(prodData[key].StockCode)
            ) {
                console.log("sd");
                prodDataSubCode.forEach(function (subCode) {
                    if (subCode.stock_code == prodData[key].StockCode) {
                        display = subCode.custom_code;
                    }
                });
            }

            let inCart = "no";

            await Object.keys(cartItemData).forEach(function (index) {
                if (
                    cartItemData[index].stock_code === prodData[key].StockCode
                ) {
                    total = cartItemData[index].qty;
                    inCart = "yes";
                }
            });

            let promoId = [];
            let promoArr = [];

            allSpecials.forEach(function (promo) {
                let items = promo.items;
                let onPromo = [];
                items.forEach(function (item) {
                    // console.log((prodData[key].StockCode).trim())
                    // console.log(item.product_stock_code)
                    // console.log(item.product_type)
                    // console.log(item.product_type == '1' && (prodData[key].StockCode).trim() == item.product_stock_code)
                    if (
                        item.product_type == "1" &&
                        prodData[key].StockCode.trim() ==
                        item.product_stock_code
                    ) {
                        onPromo.push("yes");
                    }
                });

                onPromo.forEach(function (itemsPromo) {
                    if (itemsPromo !== undefined) {
                        promoArr.push(onPromo[0]);
                        if (onPromo[0] == "yes") {
                            promoId.push(promo.id);
                        }
                    }
                });
            });

            if (favIds.includes(prodData[key].StockCode.trim())) {
                allproducts.push({
                    col0: prodData[key].StockCode,
                    col1: prodData[key].col1,
                    col2: prodData[key].StockCode,
                    col3: prodData[key].Description,
                    col4: prodData[key].InvWarehouse,
                    col5: prodData[key].SellingPrice
                        ? "R " +
                        parseFloat(prodData[key].SellingPrice).toFixed(2)
                        : "Free",
                    col6: total,
                    col7: true,
                    col8: prodData[key].LongDesc,
                    col10: prodData[key].ProductGroup,
                    col11: prodData[key].AlternateUom,
                    col12: promoArr[0],
                    col13: promoId[0],
                    col18: prodData[key].BarCode,
                    col19:
                        prodData[key].special_prices !== "" &&
                            prodData[key].special_prices !== null
                            ? "R " +
                            parseFloat(prodData[key].special_prices).toFixed(
                                2
                            )
                            : "",
                    col20: inCart,
                    col21: display,
                    col22: prodData[key].img,
                    col23: prodData[key].UserField1,
                    col24: prodData[key].AlternateKey2,
                    col25: prodData[key].InvwarehouseQty,
                    col26: prodData[key].Supplier,
                });
            }
        });

        setProducts(allproducts);
    }

    async function addFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-new-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        console.log('getFavProducts 2');
        getProducts();
    }

    async function removeFavourite(rowId) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}remove-favourite`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                rowId: rowId,
                userId: currentUser.id,
            },
        });

        console.log('getFavProducts 3');
        getProducts();
    }

    async function addItemToCart(itemId, qty) {
        itemId = itemId.trim();

        let cartItem = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}add-items-to-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: qty,
                auto: 0,
                customer_code: currentUser.customer_code,
            },
        });

        if (cartItem.data == 1) {
            let CurrentCartAmount =
                window.sessionStorage.getItem("itemsInBasket");
            let NewAmount = parseFloat(CurrentCartAmount) + 1;
            window.sessionStorage.setItem("itemsInBasket", NewAmount);
        }

        toast.success("Item Added to Cart", {
            position: "bottom-right",
            autoClose: 5000,
            closeOnClick: true,
        });

        console.log('getFavProducts 4');
        await getProducts();

        // itemId = itemId.trim();
        // let prevItemClicked = JSON.parse(window.sessionStorage.getItem("itemsClicked"));
        // if(prevItemClicked !== null){
        //   window.sessionStorage.setItem("itemsClicked", JSON.stringify([...prevItemClicked, itemId]));
        // }else{
        //   window.sessionStorage.setItem("itemsClicked", JSON.stringify(itemId));
        // }

        // let prevItemIds = JSON.parse(window.sessionStorage.getItem("itemsIdsInBasket"));

        // if(!prevItemIds.includes(itemId)){
        //   window.sessionStorage.setItem("itemsIdsInBasket", JSON.stringify([...prevItemIds, itemId]));

        //   let CurrentCartAmount = window.sessionStorage.getItem("itemsInBasket")
        //   let NewAmount = parseFloat(CurrentCartAmount) + 1
        //   window.sessionStorage.setItem("itemsInBasket", NewAmount);
        //   setCartTotal(NewAmount)

        // }else{

        //   let totalItemsClicked = JSON.parse(window.sessionStorage.getItem("itemsClicked"));
        //   let thisItem = 0;

        //   await Object.keys(totalItemsClicked).forEach(function (key) {
        //     if(totalItemsClicked[key] === itemId){
        //       thisItem += 1
        //     }
        //   });

        //   let prevItemTotals = JSON.parse(window.sessionStorage.getItem("totalItemsInBasket"));
        //   if(prevItemTotals !== null){
        //     window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([...prevItemTotals, [thisItem, itemId]]));
        //   }else{
        //     window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([[thisItem, itemId]]));
        //   }

        // }
    }

    async function setTotals(totalAmount, itemId) {
        itemId = itemId.trim();

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}update-qty-in-cart`,
            headers: { Authorization: `Bearer ${currentUser.token}` },
            data: {
                StockCode: itemId,
                id: currentUser.id,
                qty: totalAmount,
                customer_code: currentUser.customer_code,
            },
        });

        console.log('getFavProducts 5');
        await getProducts();

        // let prevItemTotals = JSON.parse(
        //     window.sessionStorage.getItem("totalItemsInBasket")
        // );
        // let found = false;

        // Object.keys(prevItemTotals).forEach(function (key) {
        //     if(prevItemTotals[key][1].trim() === itemId.trim()){
        //         found = true;
        //         prevItemTotals[key][0] = totalAmount
        //     }
        // })

        // if (prevItemTotals !== null) {
        //     if(found === false){
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([...prevItemTotals, [totalAmount, itemId]])
        //         );
        //     }else{
        //         window.sessionStorage.setItem(
        //             "totalItemsInBasket",
        //             JSON.stringify([...prevItemTotals])
        //         );
        //     }
        // }else{
        //     window.sessionStorage.setItem(
        //         "totalItemsInBasket",
        //         JSON.stringify([[totalAmount, itemId]])
        //     );
        // }
    }

    // console.log(products)

    async function openPromo(value) {
        console.log(value);
        let activeSpecial = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}special-details`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { promotionID: value },
        });

        console.log(activeSpecial.data);
        let specialData = [activeSpecial.data];
        let specialInfo = [];

        await Object.keys(specialData).forEach(async function (key) {
            let loopItems = specialData[key].items;
            let products = [];
            let free = [];

            await Object.keys(loopItems).forEach(async function (index) {
                if (loopItems[index].product_type == "1") {
                    products.push(loopItems[index].product_stock_code);
                }
                if (loopItems[index].product_type == "2") {
                    free.push(loopItems[index].product_stock_code);
                }
            });

            specialInfo.push({
                col0: specialData[key].id,
                col1: specialData[key].promotion_name,
                col2: specialData[key].from_date,
                col3: specialData[key].to_date,
                col4: specialData[key].discount_type,
                col5: specialData[key].free_amount,
                col6: specialData[key].discount_percentage,
                col7: specialData[key].special_type,
                col8: specialData[key].req_buy_amount,
                col9: specialData[key].free_product_type,
                col9: specialData[key].limit_on_order,
                col10: specialData[key].limit_per_day,
                col11: products,
                col12: free,
                col13: specialData[key].special_on,
                col14: specialData[key].ProductsGroup,
                col15: specialData[key].rules,
                col16: specialData[key].file_url,
                col17: specialData[key].promo_desc,
                col18: specialData[key].BarCode,
            });
        });

        dispatch(
            openModal({
                modalType:
                    activeSpecial.data.discount_type == "1"
                        ? "PromotionDiscount"
                        : "PromotionFree",
                modalProps: { Promotion: specialInfo[0] },
            })
        );
    }

    let imgCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Image
            </div>
        ),
        accessor: "col1",
        Cell: (tableProps) => (
            <>
                <img
                    src={tableProps.row.original.col22 !== "" ? process.env.REACT_APP_URL + 'media/' + tableProps.row.original.col22 : process.env.REACT_APP_URL + "/images/placeholder-image.png"}
                    width={150}
                    alt="Product"
                    className="mx-auto px-5 py-5"
                />
            </>
        ),
    };

    let codeCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Code
            </div>
        ),
        accessor: "col2",
        Cell: (rowInfo) => {
            return (
                <>
                    {rowInfo.row.original.col21 !== ""
                        ? rowInfo.row.original.col21
                        : rowInfo.row.original.col2}
                </>
            );
        },
    };

    let categoryCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Pet Brands
            </div>
        ),
        accessor: "col23",
        Cell: (rowInfo) => {
            return (
                <>
                    {rowInfo.row.original.col23 !== ""
                        ? rowInfo.row.original.col23 + " - " + rowInfo.row.original.col24
                        : rowInfo.row.original.col23 + " - " + rowInfo.row.original.col24}
                </>
            );
        },
    };

    let descCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Description
            </div>
        ),
        accessor: "col3",
        Cell: (rowInfo) => {
            return (
                <>
                    <p>{rowInfo.row.original.col3}</p>
                    <span>{rowInfo.row.original.col8}</span> &nbsp;&nbsp;
                    <span className="text-red-500">
                        {rowInfo.row.original.col10 == "PG008" ||
                            rowInfo.row.original.col10 == "PG003" ||
                            rowInfo.row.original.col10 == "PG007" ||
                            rowInfo.row.original.col10 == "PG001" ? (
                            <>
                                {/* <br/>  */}
                                {rowInfo.row.original.col10 == "PG008" ? (
                                    <>
                                        <span data-tip="NON-RETURNABLE. This is a product registered under the Medicines and Related Substance Act 101 of 1965 as Schedule 6 and requires a prescription to be submitted to Lakato. This product is non-returnable">
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                            />
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}

                                {rowInfo.row.original.col10 == "PG003" ||
                                    rowInfo.row.original.col10 == "PG007" ? (
                                    <>
                                        <span data-tip="NON-RETURNABLE. This is a cold-chain product and is non-returnable">
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                            />
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}

                                {rowInfo.row.original.col10 == "PG001" ? (
                                    <>
                                        <span data-tip="NON-RETURNABLE. This product registered under the Medicines and Related Substance Act 101 of 1965 and is non-returnable">
                                            <FontAwesomeIcon
                                                icon={faQuestionCircle}
                                            />
                                        </span>{" "}
                                        <ReactTooltip />
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </span>
                </>
            );
        },
    };

    let handCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Stock Status
            </div>
        ),
        accessor: "col4",
        Cell: (rowInfo) => {
            return (
                <>
                    {rowInfo.row.original.col4 === "Out of Stock" ? <p className="text-red-500">{rowInfo.row.original.col4} {currentUser.see_stock === 1 && currentUser.supplier_stock !== null && allowedSups.includes(rowInfo.row.original.col26) ? <>{"( "}{rowInfo.row.original.col25}{" )"}</> : ""}</p> : <p>{rowInfo.row.original.col4} {currentUser.see_stock === 1 && currentUser.supplier_stock !== null && allowedSups.includes(rowInfo.row.original.col26) ? <>{"( "}{rowInfo.row.original.col25}{" )"}</> : ""}</p>}

                    {rowInfo.row.original.col12 === "yes" &&
                        rowInfo.row.original.col4 !== "Out of Stock" ? (
                        <>
                            <br />
                            <p
                                className="bg-red-600 px-2 py-2 text-white text-center cursor-pointer"
                                onClick={() =>
                                    openPromo(rowInfo.row.original.col13)
                                }
                            >
                                On Promotion !
                            </p>
                        </>
                    ) : (
                        ""
                    )}

                    {/* {rowInfo.row.original.col19 !== "" &&
                    rowInfo.row.original.col12 !== "yes" &&
                    rowInfo.row.original.col4 !== "Out of Stock" ? (
                        <p className="bg-red-600 px-2 py-2 text-white text-center cursor-pointer">
                            On Promotion !
                        </p>
                    ) : (
                        ""
                    )} */}
                </>
            );
        },
    };

    let priceCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Price Excl. VAT
            </div>
        ),
        accessor: "col5",
        Cell: (rowInfo) => {
            return (
                <>
                    <p>
                        {rowInfo.row.original.col19 !== "" &&
                            rowInfo.row.original.col19 !== null && (parseFloat((rowInfo.row.original.col19).replace("R ", "")) < parseFloat((rowInfo.row.original.col5).replace("R ", ""))) ? (
                            <>
                                <del>{rowInfo.row.original.col5}</del>
                                <br />
                                {rowInfo.row.original.col19}
                            </>
                        ) : (
                            <>{rowInfo.row.original.col5}</>
                        )}

                        {rowInfo.row.original.col11 == "SD" ? (
                            <>
                                <span
                                    className="text-green-500 ml-2"
                                    data-tip="Settlement Discount if Applicable"
                                >
                                    <FontAwesomeIcon
                                        icon={faExclamationCircle}
                                    />
                                </span>{" "}
                                <ReactTooltip />
                            </>
                        ) : (
                            ""
                        )}
                    </p>
                </>
            );
        },
    };

    let qtyCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                QTY
            </div>
        ),
        accessor: "col6",
        Cell: (rowInfo) => {
            return (
                <input
                    type="number"
                    min={rowInfo.row.original.col20 == "yes" ? "1" : "0"}
                    defaultValue={rowInfo.row.original.col6}
                    className="shadow-sm text-sm border border_radius_5 w-20"
                    onChange={(values) => {
                        let mintot =
                            rowInfo.row.original.col20 == "yes" ? 1 : 0;
                        parseFloat(values.target.value) < mintot
                            ? setTotals(mintot, rowInfo.row.original.col2)
                            : setTotals(
                                values.target.value,
                                rowInfo.row.original.col2
                            );
                        if (values.target.value == 0) {
                            values.target.value = mintot;
                        } else {
                            values.target.value = Math.abs(values.target.value);
                        }
                    }}
                />
            );
        },
    };

    let cartCol = {
        Header: "",
        accessor: "col7",
        Cell: (rowInfo) => {
            return (
                <div className="flex">
                    {rowInfo.row.original.col7 === true ? (
                        <FontAwesomeIcon
                            icon={solidHeart}
                            className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer mr-1"
                            onClick={() =>
                                removeFavourite(rowInfo.row.original.col0)
                            }
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faHeart}
                            className="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer mr-1"
                            onClick={() =>
                                addFavourite(rowInfo.row.original.col0)
                            }
                        />
                    )}
                    {/* {rowInfo.row.original.col4 === "Out of Stock" ? (
                        <></>
                    ) : (
                        <> */}
                    <button
                        className="px-10 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto flex"
                        onClick={() =>
                            addItemToCart(
                                rowInfo.row.original.col2,
                                rowInfo.row.original.col6
                            )
                        }
                    >
                        {/* Add to cart */}
                        {/* + <FontAwesomeIcon icon={faShoppingCart} /> */}
                        <span className="pr-2 mt-1">+</span>{" "}
                        <FontAwesomeIcon
                            icon={faShoppingCart}
                            className="mt-2"
                        />
                    </button>
                    {/* </>
                    )} */}

                    {rowInfo.row.original.col20 == "yes" ? (
                        <span
                            className="mt-7 bg-red-500 h-6 px-2 py-1 rounded-full text-white mr-1 min-w-min_red_btn flex justify-center"
                            data-tip={
                                rowInfo.row.original.col6 +
                                " of this item is in your cart"
                            }
                        >
                            {rowInfo.row.original.col6}
                            <ReactTooltip />
                        </span>
                    ) : (
                        <div className="mt-6 h-6 px-2 py-1 rounded-full text-white"></div>
                    )}
                </div>
            );
        },
    };

    let barCol = {
        Header: () => (
            <div
                style={{
                    textAlign: "left",
                }}
            >
                Barcode
            </div>
        ),
        accessor: "col18",
        Cell: (tableProps) => <>{tableProps.row.original.col18}</>,
    };

    const data = React.useMemo(() => products, [products]);

    let all = [];

    if (imgActive === true) {
        all.push(imgCol);
    }
    if (stockActive === true) {
        all.push(codeCol);
    }
    if (barActive === true) {
        all.push(barCol);
    }
    if (descActive === true) {
        all.push(descCol);
    }
    // if(petBrandsActive === true){
    //     all.push(categoryCol);
    // }

    all.push(handCol);
    all.push(priceCol);
    all.push(qtyCol);
    all.push(cartCol);

    const columns = React.useMemo(
        () => all,
        [imgActive, stockActive, descActive, barActive, petBrandsActive]
    );

    const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
    } = tableInstance;

    // async function updateViewValue(UpdateField, updateValue){

    //   if(updateValue == true){
    //     var valueToUpdate = 1;
    //   }else{
    //     var valueToUpdate = 0;
    //   }

    //   let UpdateView = await axios({
    //     method: "post",
    //     url: `${process.env.REACT_APP_API_URL}set-view-display`,
    //     headers: {
    //         Authorization: `Bearer ${currentUser.token}`
    //     },
    //     data: {userId: currentUser.id, field: UpdateField, value: valueToUpdate},
    //   });
    // }

    async function addNewItems(file) {
        setCodeLoading(true)

        var formData = new FormData();
        var imagefile = document.querySelector("#importCodes");
        formData.append("file", imagefile.files[0]);
        formData.append("userId", currentUser.id);
        formData.append("customer_code", currentUser.customer_code);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}import-to-favourites`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });

        setCodeLoading(false)
        console.log('getFavProducts 6');
        getProducts();

    }

    return (
        <div className="bg-gray-50">
            <AdminSearchForm
                heading="Favourites"
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "Favourites", href: "/shop-favourites", count: 2 },
                ]}
            />

            <ToastContainer />

            <div className="container mx-auto relative pt-10">
                <div className="flex absolute top-0 right-0 mt-5">
                    <CustomButton
                        disabled={codeLoading}
                        submitBool={codeLoading}
                        type="submit"
                        text="Import Favourites"
                        className="inline-flex items-right px-12 py-2 ml-3 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-blue-500 hover:bg-blue-700 uppercase"
                        onClick={() =>
                            document.getElementById("importCodes").click()
                        }
                        data-tip="Stock Code in column A"
                    />
                    <input
                        id="importCodes"
                        name="importCodes"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) => {
                            addNewItems(event.target.files[0]);
                        }}
                        className="form-control"
                    />
                    <ReactTooltip />
                </div>
            </div>


            {/* <div className="container mx-auto grid place-content-end">
          <br/>
          {displaySettings == false && (
            <button
                className="px-4 py-2 mr-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto"
                onClick={() =>
                  setDisplaySettings(true)
                }
            >
                Display Settings
            </button>
          )}
          {displaySettings == true && (
          <div className="bg-gray-100 px-5 py-5">
            <p><u>Display Columns:</u> &nbsp;&nbsp;
              <input type="checkbox" value="img" name="img" defaultChecked={imgActive} onClick={(value) => {setImgActive(value.target.checked); updateViewValue('display_img', value.target.checked)}}/> Image &nbsp;&nbsp;
              <input type="checkbox" value="stock" name="stock" defaultChecked={stockActive} onClick={(value) => {setStockActive(value.target.checked); updateViewValue('display_code', value.target.checked)}}/> Stock Code &nbsp;&nbsp;
              <input type="checkbox" value="barCode" name="barCode" defaultChecked={barActive} onClick={(value) => {setBarActive(value.target.checked); updateViewValue('display_barCode', value.target.checked)}}/> BarCode &nbsp; &nbsp;
              <input type="checkbox" value="desc" name="desc" defaultChecked={descActive} onClick={(value) => {setDescActive(value.target.checked); updateViewValue('display_desc', value.target.checked)}}/> Description
              <FontAwesomeIcon icon={faTimes} onClick={() => setDisplaySettings(false)} className="text-red-500 ml-5 self-center text-xl cursor-pointer"/>
            </p>
          </div>
          )}
        </div> */}

            {/* <AdminNav cartTotal={cartTotal}/> */}
            <br />

            <div className="container mx-auto">
                <table
                    {...getTableProps()}
                    className="mb-10 w-full"
                    style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 1em",
                    }}
                >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="bg-gray-100 bg-gray-100 flex flex-col flex-no wrap md:table-row w-full"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                        className="px-2 lg:py-5 font-light"
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    className="bg-white rounded mt-5 bg-white rounded mt-5 flex flex-col flex-no wrap md:table-row"
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                style={{
                                                    // borderRight:
                                                    // "1px solid #EFEFEF",
                                                    textAlign: "left",
                                                }}
                                                className="mx-auto text-center relative px-2 py-2 text-sm lg:border-r md:border-r border-slate-300"
                                            >
                                                {cell.row.original.col12 ===
                                                    "yes" &&
                                                    cell.row.original.col4 !=
                                                    "Out of Stock" &&
                                                    imgActive === true &&
                                                    cell.render("Cell").props.cell
                                                        .column.id === "col1" ? (
                                                    <div
                                                        className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                        style={{
                                                            clipPath:
                                                                "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                        }}
                                                    >
                                                        <p className="px-4 py-1.5 text-white font-bold">
                                                            PROMOTION
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {cell.row.original.col12 ===
                                                    "yes" &&
                                                    cell.row.original.col4 !=
                                                    "Out of Stock" &&
                                                    imgActive === false &&
                                                    stockActive === true &&
                                                    cell.render("Cell").props.cell
                                                        .column.id === "col2" ? (
                                                    <div
                                                        className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                        style={{
                                                            clipPath:
                                                                "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                        }}
                                                    >
                                                        <p className="px-4 py-1.5 text-white font-bold">
                                                            PROMOTION
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {cell.row.original.col12 ===
                                                    "yes" &&
                                                    cell.row.original.col4 !=
                                                    "Out of Stock" &&
                                                    imgActive === false &&
                                                    stockActive === false &&
                                                    barActive === true &&
                                                    cell.render("Cell").props.cell
                                                        .column.id === "col18" ? (
                                                    <div
                                                        className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                        style={{
                                                            clipPath:
                                                                "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                        }}
                                                    >
                                                        <p className="px-4 py-1.5 text-white font-bold">
                                                            PROMOTION
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {cell.row.original.col12 ===
                                                    "yes" &&
                                                    cell.row.original.col4 !=
                                                    "Out of Stock" &&
                                                    imgActive === false &&
                                                    stockActive === false &&
                                                    barActive === false &&
                                                    descActive === true &&
                                                    cell.render("Cell").props.cell
                                                        .column.id === "col3" ? (
                                                    <div
                                                        className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                        style={{
                                                            clipPath:
                                                                "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                        }}
                                                    >
                                                        <p className="px-4 py-1.5 text-white font-bold">
                                                            PROMOTION
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {cell.row.original.col12 ===
                                                    "yes" &&
                                                    cell.row.original.col4 !=
                                                    "Out of Stock" &&
                                                    imgActive === false &&
                                                    stockActive === false &&
                                                    barActive === false &&
                                                    descActive === false &&
                                                    cell.render("Cell").props.cell
                                                        .column.id === "col4" ? (
                                                    <div
                                                        className="bg-red-600 h-8 w-full absolute top-0 left-0 sm:hidden"
                                                        style={{
                                                            clipPath:
                                                                "polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%)",
                                                        }}
                                                    >
                                                        <p className="px-4 py-1.5 text-white font-bold">
                                                            PROMOTION
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {cell.render("Cell")
                                                    .props.cell.column
                                                    .id === "col2" ||
                                                    cell.render("Cell")
                                                        .props.cell.column
                                                        .id === "col4" ? (
                                                    <p
                                                        className="text-gray-500 text-2xl lg:text-sm md:text-sm"
                                                        style={{
                                                            textAlign:
                                                                "left",
                                                        }}
                                                    >
                                                        {cell.render(
                                                            "Cell"
                                                        )}
                                                    </p>
                                                ) : (
                                                    <>
                                                        {cell.render("Cell")
                                                            .props.cell.column
                                                            .id === "col3" ? <p className="text-center lg:text-left md:text-left">{cell.render("Cell")}</p> : cell.render("Cell")}
                                                    </>
                                                    // cell.render("Cell")
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <nav
                    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                    aria-label="Pagination"
                >
                    <div className="flex-1 flex justify-between sm:justify-end">
                        <button
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div>
                </nav>
            </div>
        </div>
    );
}
