import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { usePagination, useTable, useSortBy } from "react-table";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";

export function UserManagement(props) {
    const { currentUser } = useSelector((state) => state.auth);

    let pathname = props.location.pathname;
    let newPathname = pathname.replace("/", "");
    let pageName = newPathname.replaceAll("-", " ");

    const [cartTotal, setCartTotal] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setCartTotal(
            parseFloat(window.sessionStorage.getItem("itemsInBasket"))
        );
        getUsers("");
    }, []);

    async function getUsers(searchUser) {
        let userList = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-user-list`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { searchUser: searchUser },
        });

        let allUsers = userList.data;
        let userData = [];

        await Object.keys(allUsers).forEach(function (key) {
            let user_type = "Customer";
            if (allUsers[key].is_admin === 1) {
                user_type = "Admin";

                if (allUsers[key].is_sales === 1) {
                    user_type = "Sales Rep";
                }

                if (allUsers[key].is_lakato === 1) {
                    user_type = "Lakato Sales";
                }
            }

            userData.push({
                col0: allUsers[key].id,
                col1: allUsers[key].first_name,
                col2: allUsers[key].last_name,
                col3: allUsers[key].email,
                col4: user_type,
                col5:
                    allUsers[key].customer_code !== null &&
                    allUsers[key].customer_code.includes(",")
                        ? allUsers[key].customer_code.split(",").join(", ")
                        : allUsers[key].customer_code,
            });
        });

        setUsers(userData);
    }

    const data = React.useMemo(() => users, [users]);

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        First Name
                    </div>
                ),
                accessor: "col1",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Last Name
                    </div>
                ),
                accessor: "col2",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Email Address
                    </div>
                ),
                accessor: "col3",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        User Type
                    </div>
                ),
                accessor: "col4",
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Customer Code
                    </div>
                ),
                accessor: "col5",
                Cell: (rowInfo) => {
                    return (
                        <div className="flex flex-wrap">
                            <div>{rowInfo.row.original.col5}</div>
                        </div>
                    );
                },
            },
            {
                Header: "",
                accessor: "col0",
                Cell: (rowInfo) => {
                    return (
                        <div>
                            <Link
                                as="a"
                                to={"/edit/user/" + rowInfo.row.original.col0}
                                className="hover:text-lk_dark_grey_button_hover"
                            >
                                <button className="px-10 py-2 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto">
                                    Edit
                                </button>
                            </Link>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        prepareRow,
    } = tableInstance;

    return (
        <>
            <div className="bg-gray-50">
                <AdminSearchForm
                    heading={pageName}
                    color="text-gray-500"
                    header_color="bg-gradient-to-b from-gray-300"
                    crumb_color="text-black"
                    breadcrumbs={[
                        { name: "Home", href: "/", count: 1 },
                        { name: pageName, href: pathname, count: 2 },
                    ]}
                />

                {/* <AdminNav cartTotal={cartTotal} /> */}
                <br />
                <br />

                <div className="container mx-auto flex justify-between">
                    {currentUser && currentUser.isAdmin === 1 && (
                        <>
                            <Link
                                as="a"
                                to={"/edit/user/0"}
                                className="hover:text-lk_dark_grey_button_hover"
                            >
                                <button className="px-10 py-2 mb-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto">
                                    Add new user
                                </button>
                            </Link>

                            <Link
                                as="a"
                                to={"/system-settings"}
                                className="hover:text-lk_dark_grey_button_hover"
                            >
                                <button className="px-10 py-2 mb-5 border border-transparent text-base font-medium border_radius_4 shadow-sm text-white bg-lk_grey_one hover:bg-lk_dark_grey_button_hover uppercase mx-auto">
                                    Settings
                                </button>
                            </Link>
                        </>
                    )}
                </div>
                <div className="container mx-auto flex flex-row-reverse">
                    <small>V 1.5.8</small>
                </div>

                <div className="container mx-auto">
                    <input
                        type="text"
                        onChange={(e) => getUsers(e.target.value)}
                        placeholder="Search User..."
                        className="rounded-lg p-2 w-96"
                    />

                    <table
                        {...getTableProps()}
                        className="mb-10 w-full"
                        style={{
                            borderCollapse: "separate",
                            borderSpacing: "0 1em",
                        }}
                    >
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    className="bg-gray-100"
                                >
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                            className="px-2 py-5 font-light"
                                        >
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="bg-white rounded mt-5"
                                    >
                                        {row.cells.map((cell) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    style={{
                                                        borderRight:
                                                            "1px solid #EFEFEF",
                                                        textAlign: "left",
                                                    }}
                                                    className="mx-auto text-center relative px-2 py-2 text-sm"
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 bg-gray-50"
                        aria-label="Pagination"
                    >
                        <div className="flex-1 flex justify-between sm:justify-end">
                            <button
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                Previous
                            </button>
                            <button
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                                Next
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}
